<template>
  <v-row dense>
    <v-col class="pl-10" cols="12" md="7">
      <v-text-field
        v-model="product.product_name"
        label="Nome do produto*"
        placeholder="Nome do produto"
        :rules="[() => !!product.product_name || 'Este campo é obrigatório']"
        outlined
        rounded
        dense
      />
      <v-select
        v-model="product.factor_id"
        label="Tabela de Fator"
        :items="factors"
        item-text="cost"
        item-value="id"
        outlined
        rounded
        dense
      />
      <v-row dense>
        <v-col cols="12" md="5">
          <v-text-field
            v-model="product.product_reference"
            label="Referência*"
            placeholder="N° de referência"
            :rules="[() => !!product.product_reference || 'Este campo é obrigatório']"
            outlined
            rounded
            dense
          />
        </v-col>
        <v-col cols="12" md="7">
          <v-select
            v-model="product.product_ncm"
            outlined
            label="NCM"
            :items="ncms"
            item-text="title"
            item-value="id"
            rounded
            dense
          />
        </v-col>
      </v-row>
      <v-textarea
        v-model="product.product_description"
        label="Descrição"
        placeholder="Descrição do produto"
        auto-grow
        outlined
        dense
        rounded
      />
    </v-col>
    <v-col class="pr-10" cols="12" md="5">
      <v-row dense>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="product.product_height"
            label="Altura (cm)"
            placeholder="Altura"
            outlined
            rounded
            dense
          />
          <v-text-field
            v-model="product.product_diameter"
            label="Diâmetro (mm)"
            placeholder="Diâmetro"
            outlined
            rounded
            dense
          />
          <v-text-field
            v-model="product.product_depth"
            label="Profundidade (mm)"
            placeholder="Profundidade"
            outlined
            rounded
            dense
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="product.product_width"
            label="Largura (cm)"
            placeholder="Largura"
            outlined
            rounded
            dense
          />
          <v-text-field
            v-model="product.product_weight"
            label="Peso (g)"
            placeholder="Peso"
            outlined
            rounded
            dense
          />
          <v-text-field
            v-model="product.product_energy"
            label="Energia (v)"
            placeholder="Energia"
            outlined
            rounded
            dense
          />
        </v-col>
      </v-row>
      <v-combobox
        v-model="product.product_keywords"
        label="Keywords"
        multiple
        small-chips
        deletable-chips
        outlined
        rounded
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data: function() {
    return {
      ncms: [],
      factors: [],
      items:[] 
    }
  },
  methods: {
    onChangeCombobox(items, type) {
      if (type === "main-categories") {
        this.product.product_main_categories = items.filter((item) =>
          this.mainCategories.includes(item)
        );
      } else if (type === "segments") {
        this.product.product_segments = items.filter((item) =>
          this.segments.includes(item)
        );
      } else if (type === "special-dates") {
        this.product.product_special_dates = items.filter((item) =>
          this.specialDates.includes(item)
        );
      }
    },
    getNcms() {
      this.$api
        .get("/ncms")
        .then((res) => {
          this.ncms = res.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getFactors() {
      this.$api
        .get("/product_factors",{
          headers: {
            Authorization: `Bearer ${this.$cookies.get('token')}`,
          }
        })
        .then((res) => {
          this.factors = res.data.data
        })
        .catch((err) => {
          console.error(err);
        });
    }
  },
  mounted() {
    this.getNcms()
    this.getFactors()
  },
};
</script>
